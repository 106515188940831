@import '../../resources/colors-and-fonts';

$toolbar-height: 60px;
$animation: all 0.2s ease-out;

.side-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65px;
  color: $lighter_text;
  background-color: $tile_background_color;
  box-shadow: $default_box_shadow;
  z-index: 11;
  transition: $animation;
  padding-left: 15px;
  box-sizing: border-box;

  &.menu-extended {
    width: 250px;
    align-items: flex-start;
  }

  .page-name {
    width: 0;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    transition: $animation;
    opacity: 0;

    &.page-name-extended {
      width: 100%;
      opacity: 1;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    font-size: 26px;

    &:hover {
      cursor: pointer;
    }

    .logo-name {
      font-size: 20px;
      font-weight: $font_weight_bold;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .menu-arrow {
    position: absolute;
    top: 40px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $selection_color;
    font-size: 20px;
    transition: 1s;

    &:hover {
      cursor: pointer;
    }

    &.rotate-left {
      transition: 0.5s;
      transform: rotate(360deg);
    }

    &.rotate-right {
      transition: 0.5s;
      transform: rotate(-540deg);
    }
  }

  .pages {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;

    .separator {
      display: flex;
      align-items: center;
      height: 23px;
      margin-bottom: 15px;
      white-space: nowrap;
      overflow: hidden;
      margin-left: -12px;

      .separator-name {
        color: $kashmir_blue;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: $font-weight_bold;
        padding-left: 12px;
      }

      .separator-line {
        height: 2px;
        width: 100%;
        margin-right: 12px;
        margin-left: 8px;
        background-color: $kashmir_blue;
        opacity: 1;
      }
    }

    .page {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $lighter_text;
      text-decoration: none;
      font-size: 32px;

      &.page-extended {
        justify-content: flex-start;
      }

      .icon {
        font-size: 28px;
        margin-right: 10px;
      }

      .icon-parcel-locker {
        font-size: 24px;
      }

      &:hover {
        color: $selection_color;
      }

      &.active {
        color: $selection_color;
      }
    }
  }

  .language-section {
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px;
    transition: $animation;
    margin-right: 10px;
  }

  .logout {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
      color: $selection_color;
    }

    .icon {
      font-size: 32px;
      margin-right: 10px;
    }
  }
}
