@import '../../../../../resources/colors-and-fonts';

.view-point-details-menu-container {
  .view-point-details-menu {
    width: 336px;
    position: relative;
    z-index: 5;
    padding: 32px 24px;
    background-color: $tile_background_color;
    box-shadow: $default_box_shadow;
    font-family: 'Poppins', sans-serif;
    font-style: normal;

    .back-button {
      display: flex;
      align-items: center;
      font-weight: $font_weight_bold;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 32px;

      &:hover {
        cursor: pointer;
        color: $selection_color;
      }

      .icon-keyboard-arrow-left {
        font-size: 24px;
      }
    }

    .table-title {
      display: flex;
      align-items: center;
      font-weight: $font_weight_bold;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid $kashmir_blue;
      margin-bottom: 16px;

      .background-image {
        display: flex;
        align-items: center;
        width: 18px;
        height: 18px;
        margin-left: 9px;
      }
    }

    .table-items {
      width: 100%;
      font-weight: $font_weight_normal;
      font-size: 14px;
      margin-bottom: 19px;

      .table-item-row {
        display: flex;
        line-height: 21px;
        padding: 6px 0;

        &:first-child {
          padding: 0 0 6px 0;
        }

        &:last-child {
          padding: 6px 0 0 0;
        }

        .item-name {
          font-weight: $font_weight_bold;
          width: 111px;
        }
      }
    }
  }
}
