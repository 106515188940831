@import '../../../../resources/colors-and-fonts';

.marker-popup-row-with-icon {
  display: flex;


  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 35px;
    margin-right: 10px;

    .icon {
      font-size: 30px;
      color: #a0a7b4;
    }

    .icon-road {
      font-size: 25px;
    }

    .icon-phone {
      font-size: 25px;
    }

    .icon-parcel-locker {
      font-size: 25px;
    }

    .icon-person-distance {
      font-size: 28px;
    }

    .circle {
      width: 35px;
      height: 35px;
      border-radius: 50%;

      &.delivery {
        background-color: $deliveries_color;
      }

      &.pickup {
        background-color: $pickups_color;
      }

      &.delivery-dim {
        background-color: $deliveries_dim_color;
      }

      &.pickup-dim {
        background-color: $pickups_dim_color;
      }

      &.unsuccessful-attempt {
        background-color: $warning_color;
      }
    }
  }


  .data-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;

    &.delivery {
      color: $deliveries_color;
    }

    &.pickup {
      color: $pickups_color;
    }

    .delivery-dim {
      color: $deliveries_dim_color;
    }

    .pickup-dim {
      color: $pickups_dim_color;
    }

    .unsuccessful-attempt {
      color: $warning_color;
    }

    .data {
      font-size: 16px;
      color: $lighter_text;

      .data-value {
        padding-left: 10px;
      }
    }
  }
}
