@import '../../resources/colors-and-fonts';

.error-boundary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    margin-bottom: 20px;
    font-size: 20px;
    color: $light_text;
  }
}
