@import '../../../../resources/colors-and-fonts';

.circle-wrapper {
  position: absolute;
  top: -8px;
  left: 17px;
  resize: both;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  border-radius: 50%;
  padding: 2px;
  background-color: $kashmir_blue;
  box-sizing: border-box;
  min-height: 20px;
  min-width: 20px;


  .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lighter_text;
    font-family: $font_family;
    font-weight: $font-weight_bold;
    font-size: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}

