@import '../../../resources/colors-and-fonts';

.map-point-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 1px rgba(19, 49, 86, 0.6);
  border-radius: 9999px;
  border: 2px #fffcf5 solid;

  &:hover {
    box-shadow: 0 4px 4px rgba(43.51, 65.29, 89.25, 0.8);
    cursor: pointer;
  }

  &.selected {
    box-shadow: 0 4px 4px rgba(43.51, 65.29, 89.25, 0.8);
  }

  &.standard {
    background: #1d2f6f;
  }

  &.disabled {
    background: #989898;
  }

  &.most-impactful {
    background: $success_color;
  }

  &.least-impactful {
    background: $error_color;
  }

  .selected-ring {
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 9999px;
    border: 2px #fffcf5 solid;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 155, 250, 0) 55%,
      rgba(0, 155, 250, 1) 50%
    );
  }

  .icon {
    position: relative;
    color: #fffcf5;
    pointer-events: none;
  }

  .icon-parcel-locker-new {
    font-size: 11px;
    padding-left: 1px;
  }

  .icon-demo-shops-new {
    font-size: 12px;
  }

  .path1 {
    color: $error_color;
  }

  .icon-gas-station-new {
    font-size: 15px;
  }

  .icon-number-one {
    font-size: 11px;
    padding-bottom: 1px;
  }

  .icon-number-two {
    font-size: 11px;
    padding-bottom: 1px;
  }

  .icon-number-three {
    font-size: 12px;
  }
}
