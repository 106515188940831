@import '../../../../resources/colors-and-fonts';

.multiple-markers-popup {
  padding: 10px;
  font-family: $font_family;
  font-weight: $font-weight_normal;
  color: $light_text;
  min-width: 400px;
  z-index: 20;

  .card-wrapper {
    width: 100%;

    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      flex-grow: 1;
      font-size: 17px;
      padding: 5px 0;

      .header-data-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $lighter_text;
        flex-grow: 1;
        width: 250px;

        .delivery-type-wrapper {
          display: flex;
          flex-wrap: wrap;


          .delivery-type {
            text-transform: uppercase;
            padding-right: 10px;

            &.delivery {
              color: $deliveries_color;
            }

            &.pickup {
              color: $pickups_color;
            }

            &.delivery-dim {
              color: $deliveries_dim_color;
            }

            &.pickup-dim {
              color: $pickups_dim_color;
            }

            &.unsuccessful-attempt {
              color: $warning_color;
            }
          }

          .shipment-code {
            display: flex;
          }

        }

        .name {
          font-weight: $font_weight_bold;
        }
      }

      .arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        background-color: $tile_background_color_with_opacity;

        &:hover {
          color: $selection_color;
          cursor: pointer;
        }
      }
    }

  }

  .distance {
    color: #fff;
    font-size: 16px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    font-style: italic;

    .pause {
      color: $warning_color;
    }
  }
}
