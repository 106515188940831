@import '../../../resources/colors-and-fonts';

.delivery-area-analysis {
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: $light_text;

  .controls-wrapper {
    position: absolute;
    top: 0;
    margin-top: 10px;
    margin-left: 10px;

    .filter-wrapper {
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      background-color: $tile_background_color;
      box-shadow: $menu_box_shadow;
      border-radius: 4px;

      &.extend-width {
        width: 663px;
        box-sizing: border-box;
        box-shadow: none;
      }

      .center-selection-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .label {
          min-width: 130px;
          font-weight: $font_weight_bold;
        }

        .select {
          width: 100%;
        }
      }

      .date-range {
        display: flex;
        margin-bottom: 20px;
      }

      .title {
        flex-shrink: 0;
        font-size: 16px;
        font-weight: $font_weight_bold;
        width: 130px;
      }

      .range-picker-wrapper {
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .quick-links {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        font-size: 14px;
        text-decoration: underline;

        & div {
          &:hover {
            color: $selection_color;
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: $selection_color;
          }
        }
      }
    }

    .mode-buttons {
      .mily-button {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }

  .right-menu {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 400px;

    .right-menu-buttons {
      margin-top: 24px;
      position: relative;
      float: right;

      .mode-button-wrapper {
        margin: 0;
      }
    }

    .right-menu-menus {
      position: relative;
      float: right;
      width: fit-content;
      display: block;

      .point-menu {
        display: none;

        &.expanded {
          display: block;
          visibility: visible;
          height: auto;
          width: auto;
        }
      }

      .distance-polygon-config-menu-wrapper {
        display: none;

        &.expanded {
          display: block;
          visibility: visible;
          height: auto;
          width: auto;
        }
      }
    }
  }

  .map {
    flex-grow: 1;
    position: relative;

    .map-buttons {
      position: absolute;
      bottom: 10px;
      left: 10px;

      .info-button {
        position: relative;
        margin-bottom: 20px;
        white-space: pre-line;
      }

      .region-map-buttons {
        display: flex;

        .map-type-button {
          border-radius: 4px;
        }

        .button {
          font-size: 45px;
          margin-left: 12px;

          &.active {
            color: $selection_color;
          }

          &:hover {
            color: $selection_color;
          }

          .icon-build {
            font-size: 40px;
          }
        }
      }
    }
  }

  .chart {
    height: 345px;
    transition: height 1s ease-in-out;
    overflow: hidden;

    &.hidden {
      height: 0;
    }
  }
}
