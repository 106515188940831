@import '../../../../../resources/colors-and-fonts';

.map-type-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  z-index: 10;
  background-color: $tile_background_color;
  border: 2px solid #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  transition: bottom 0.05s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
