@import '../../../../resources/colors-and-fonts';

.preview-card {
  .title-wrapper {
    display: flex;

    .color {
      min-width: 20px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .values {
      display: flex;
      flex-wrap: wrap;

      .cell-wrapper {
        font-size: 14px;
        margin-right: 10px;
        display: flex;
        align-items: center;

        &:first-child {
          width: 100%;
          font-weight: $font_weight_bold;
          margin-bottom: 5px;
        }

        .cell-header {
          margin-right: 5px;
          color: $kashmir_blue;
          font-size: 18px;

          .icon-alarm {
            font-size: 22px;
          }
        }

        .duration {
          margin-right: 25px;
        }
      }
    }
  }

  .plan-courier-select {
    margin-top: 10px;
    margin-left: 30px;
  }
}
