@import '../../../resources/colors-and-fonts';

.copy-to-clipboard-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }

  .icon-content-copy {
    font-size: 15px;
  }

  .copied {
    position: absolute;
    left: -25px;
    top: 20px;
    font-size: 14px;
    background-color: $tile_background_color_with_opacity;
    padding: 5px;
    border-radius: 5px;
    z-index: 50;
    text-transform: capitalize;
  }
}
