@import '../../../../resources/colors-and-fonts';

.radio-button-group {
  display: flex;

  .radio-title {
    font-weight: $font_weight_normal;
    font-size: 16px;
  }
}
