@import '../../../resources/colors-and-fonts';

.modal {
  font-size: 16px;
  background-color: $tile_background_color_with_opacity;
  width: 650px;
  height: 700px;
  padding: 10px;
  border: 1px solid $tile_border_color;
  box-shadow: $default_box_shadow;
  display: flex;
  flex-direction: column;

  .close {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    height: 25px;
    width: 25px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #fff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    box-sizing: border-box;
    outline: none;
    text-align: center;
    z-index: 25;
  }


  .address-data {
    position: relative;
    z-index: 20;
    background-color: $tile_background_color;
    display: flex;
    flex-direction: column;
    padding: 10px;
    color: $light_text;
    align-items: center;
  }

  .labeling-map-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
