@import '../../../../resources/colors-and-fonts';

.ooh-point-analysis-map-wrapper {
  height: 100%;
  position: relative;

  .small-italic {
    font-style: italic;
    font-size: small;
    text-transform: none;
  }

  .marker {
    background-image: url('../../../../resources/pin-thin.svg');
    image-rendering: pixelated;
    margin-top: -12px;
    background-size: contain;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .shipments-wrapper {
    align-items: center;
    display: flex;
    margin-right: 0;

    .icon {
      font-size: 20px;
    }
  }

  .padding {
    padding-left: 3px;
  }
}
