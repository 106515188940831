@import '../../../resources/colors-and-fonts';

.shipment-timeline {
  .timeline-item-wrapper {
    display: flex;
    margin-bottom: 10px;
    margin-right: 15px;

    .map-marker {
      margin-bottom: 10px;
    }

    .timeline-separator {
      display: flex;
      align-items: center;

      .timeline-total {
        display: flex;
        color: $light_text;
        align-self: center;
      }

      .flag {
        font-size: 24px;
      }
    }

    .data-wrapper {
      width: 100%;
      margin-left: 11px;

      .last-timeline-item {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 3px 10px;
        border: 1px solid $kashmir_blue;
        color: $light_text;
      }

    }
  }
}
