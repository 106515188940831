@import '../../../../resources/colors-and-fonts';

.alert-dialog-component {
  padding: 20px;
  font-weight: $font_weight_normal;

  .title {
    font-weight: $font_weight_bold;
    font-size: 18px;
  }

  .body {
    color: $light_text;
    margin: 20px 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}
