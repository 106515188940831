@import '../../../../resources/colors-and-fonts';

.hub-management-map-wrapper {
  height: 100%;
  position: relative;

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }
  }
}

.hub-add-map-wrapper {
  height: calc(100vh - 56px);
  position: relative;

  .mapboxgl-marker :hover {
    cursor: pointer;
  }

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }
  }
}
