@import '../../../../resources/colors-and-fonts';

.shipments-per-courier-upload-component {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .warning {
    display: flex;
    align-items: center;
    margin: 0 10px 10px 10px;
    padding: 10px;
    color: $light_text;
    background-color: $warning_color_dim;

    .icon {
      color: $warning_color;
      font-size: 25px;
      margin-right: 5px;
    }
  }

  .selection-wrapper {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 10px 0;
    max-width: 400px;

    .label {
      color: $lighter_text;
      font-weight: $font_weight_bold;
      margin-right: 10px;
    }

    .select {
      width: 100%;
    }
  }

  .table-view {
    padding: 0 10px 10px 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 65px);

    .table-wrapper {
      background-color: $tile_background_color;
      flex-grow: 1;
      overflow: scroll;

      .flex-3 {
        flex-grow: 3;
      }

      .flex-2 {
        flex-grow: 2;
      }

      .table-body {
        .table-row {
          .table-cell {
            overflow-wrap: anywhere;

            &:nth-child(2) {
              flex-grow: 2;
            }

            &:nth-child(4) {
              flex-grow: 3;
            }
          }

          .editable-cell {
            background-color: transparent;
            border: 0;
            color: $light_text;
            font-family: $font_family;
            width: 100%;
            font-size: 14px;

            &:hover {
              cursor: text;
            }
          }

          &.warning-row {
            background-color: $warning_color;

            &:hover {
              background-color: $selection_color;
              color: $lighter_text;
            }
          }
        }
      }
    }
  }

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: $lighter_text;
  }
}
