@import '../../../../../resources/colors-and-fonts';

.info-dialog {
  padding: 15px;
  background-color: $tile_background_color;
  border: 1px solid $kashmir_blue;
  color: $lighter_text;
  font-size: 12px;
  max-width: 300px;
  font-family: $font_family;

  .close-dialog {
    position: absolute;
    top: 2px;
    right: 5px;

    .icon-close {
      font-size: 16px;

      &:hover {
        cursor: pointer;
        color: $selection_color;
      }
    }
  }
}
