@import '../../../resources/colors-and-fonts';

.time-picker-wrapper {
  margin-top: 10px;
  padding-bottom: 5px;
  width: auto;

  .input-wrapper {
    position: relative;

    input {
      &[type='time'] {
        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }

    .form-input-wrapper {
      margin-top: 5px;

      .text-input {
        .default-input {
          width: 100px;
        }
      }
    }

    .input-error {
      position: absolute;
      bottom: -17px;
    }
  }
}
