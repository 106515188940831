@import '../../../../resources/colors-and-fonts';

.info-dialog-wrapper {
  .title {
    display: flex;
    align-items: center;
    color: $light_text;
    font-weight: $font_weight_bold;
    margin-bottom: 10px;
  }

  .info-icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 5px;
    position: relative;

    &:hover {
      cursor: pointer;
      color: $selection_color;

      .icon-background {
        position: absolute;
        background-color: $light_text;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        z-index: 1;
      }
    }

    .icon-info {
      font-size: 24px;
      z-index: 5;
    }
  }
}
