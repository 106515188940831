@import '../../../../resources/colors-and-fonts';

.stops-timeline {
  .timeline-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    color: $lighter_text;
    border: solid 1px;
    box-shadow: 0 0 1px #000;
    border-radius: 70% 70% 70% 0;
    transform: rotate(-45deg),;
    margin-top: 6px;
    font-size: 9px;

    &:hover {
      cursor: pointer;
    }

    &:active {
      box-shadow: 0 0 8px $lighter_text;
      margin: 2px 0 10px 0;
    }

    &.delivery {
      background-color: $deliveries_color;
    }

    &.pickup {
      background-color: $pickups_color;
    }

    &.pause {
      background-color: $warning_color;
    }

    .stop-number {
      transform: rotate(45deg);
      -webkit-user-select: none; // Chrome all / Safari all
      -moz-user-select: none; // Firefox all
      -ms-user-select: none; // IE 10+
      user-select: none; // Likely future
    }
  }

  .ui-icon-style {
    align-items: center;
    display: flex;
    margin-right: 10px;
    font-size: 20px;
  }

  .stop-timeline-row {
    display: flex;
    align-items: center;
    color: $light_text;
    font-family: $font_family;
    text-transform: uppercase;
    font-weight: $font-weight_bold;
  }

  .shipments {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 15px;

    &.cursor-pointer {
      cursor: pointer;
    }

    & .timeline-item-icon {
      font-size: 20px;
      margin-right: 5px;
    }

    .sh-pk-icon {
      margin-left: 10px;
    }

    .arrow {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .flag {
    font-size: 24px;
  }
}
