@import '../../../resources/colors-and-fonts';

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: 15px;
  height: 40px;
  left: 0;
  right: 0;

  .footer-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    opacity: 0.7;
    text-align: left;

    :not(.footer-data:last-of-type) {
      margin-right: 10px;
    }
  }
}
