@import '../../../resources/colors-and-fonts';

.user-permissions {
  padding: 10px;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .tab-list {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      .tab {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        color: $light_text;
        font-family: $font-family;
        font-size: 14px;
        font-weight: $font_weight_bold;
        height: 48px;
        box-sizing: border-box;

        &:hover {
          cursor: pointer;
          color: $selection_color;
        }

        .icon {
          margin-right: 10px;
          font-size: 19px;
        }

        .icon-clipboard-list {
          font-size: 18px;
        }
      }

      .active-tab {
        color: $selection_color;
        background-color: $tile-background_color;
      }
    }

    .active-tab-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .user-management,
      .role-management {
        flex-grow: 1;

        .table-wrapper {
          height: 100%;
          max-height: 100%;
        }
      }

      .user-management {
        .table-component {
          .header {
            .table-cell {
              &:nth-child(3) {
                flex-grow: 3;
              }
            }
          }

          .table-body {
            .table-cell {
              &:nth-child(3) {
                flex-grow: 3;
              }
            }
          }
        }
      }
    }
  }
}
