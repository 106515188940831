@import '../../../../resources/colors-and-fonts';

.single-radio-button {
  display: flex;
  font-size: 16px;
  align-items: center;

  .value-name {
    font-weight: $font_weight_normal;
  }

  input {
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 0;
    -webkit-appearance: none;
    appearance: none;
    // for iOS < 15 to remove gradient background
    background-color: transparent;
    border: 2px solid $border_color_light;
    border-radius: 50%;
    box-sizing: border-box;


    // Create custom radio button
    &::before {
      // Button inner circle
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }

    &:hover {
      &:not(:disabled) {
        border-color: $border_color_dark;
        background-color: $border_color_dark;
      }
    }

    &:disabled {
      border-color: #989898;
    }

    &:checked {
      border-color: $selection_color;

      &:not(:disabled) {
        // Outer circle hover effect
        &:hover {
          border-color: $border_color_dark;
          background-color: transparent;
        }

        &::before {
          transform: scale(1);
          border-color: $selection_color;
          background-color: $selection_color;
        }
      }

      &:disabled {
        border-color: #989898;

        &::before {
          transform: scale(1);
          border-color: #989898;
          background-color: #989898;
        }
      }
    }

    &.radio-button-hovered {
      &:checked {
        &::before {
          // Inner circle hover effect
          transform: scale(1);
          border-color: $border_color_dark;
          background-color: $border_color_dark;
        }
      }
    }
  }
}
