@import '../../../../resources/colors-and-fonts';

.isochrone-polygon-symbol {
  box-sizing: border-box;
  width: 24px;
  height: 12px;

  border: 2px solid $polygon_border_color;
  border-radius: 7px;
}
