.delivery-area-analysis-map {
  height: 100%;
  position: relative;

  .context-menu-popup {
    .mapboxgl-popup-content {
      padding: 5px;
    }
  }

  .mapbox-improve-map {
    display: none;
  }

  .region-displayer {
    display: flex;
    padding: 0 0 0 5px;
    position: absolute;
    bottom: -12px;
    right: 210px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #000000bf;
    background-color: #ffffff80;
    z-index: 10;
  }

  .meta-attribution {
    display: flex;
    padding: 0 0 0 5px;
    position: absolute;
    bottom: -12px;
    right: 165px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #000000bf;
    background-color: #ffffff80;
    z-index: 10;

    a {
      color: #000000bf;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
