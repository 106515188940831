@import '../../../resources/colors-and-fonts';

.map-marker {
  position: relative;

  .pin {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: $selection_color;
    width: 25px;
    height: 25px;
    color: $lighter_text;
    font-family: $font_family;
    border: solid 1px;
    box-shadow: 0 0 1px #000;
    cursor: pointer;
    border-radius: 70% 70% 0;
    transform: rotate(45deg);
    transform-origin: center;
    z-index: 10;

    &.delivery {
      background: $deliveries_color;
    }

    &.pickup {
      background: $pickups_color;
    }

    &.stop-after-pause {
      background: $warning_color;
    }

    .text {
      font-size: 11px;
      transform: rotate(-45deg);
      font-weight: $font-weight_bold;
    }

    .icon {
      font-size: 12px;
      transform: rotate(-45deg);
    }

    &.small {
      width: 20px;
      height: 20px;

      .text {
        font-size: xx-small;
      }
    }

    &.route-start {
      background: #088a24;
    }

    &.route-end {
      background: #f44336;
    }

    &.route-start-end {
      background: linear-gradient(45deg, #f44336 50%, #088a24 50%);
    }

    &.delivery-dim {
      background-color: $deliveries_dim_color;
    }

    &.pickup-dim {
      background-color: $pickups_dim_color;
    }

    &.unsuccessful-attempt {
      background-color: $warning_color;
    }

    &.wrong {
      background-color: $error_color;
    }
  }

  &.feature-big {
    .pin {
      width: 25px;
      height: 25px;

      .text {
        font-size: 12px;
      }
    }
  }

  &.highlight {
    .pin {
      width: 25px;
      height: 25px;
      background: $selection_color;

      .text {
        font-size: 12px;
      }
    }
  }

  .background-image {
    width: 25px;
    height: 25px;
    cursor: pointer;
    image-rendering: pixelated;
    z-index: 2;

    &.package-locker-icon {
      width: 30px;
      height: 30px;
    }

    &.demo-shop-icon {
      width: 30px;
      height: 30px;
    }
  }

  .animation-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    .pulsating-circle {
      position: absolute;
      width: 200%;
      height: 200%;
      border-radius: 50%;
      background-color: $selection_color;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      box-sizing: border-box;
      z-index: 5;
    }

    @keyframes pulse-ring {
      0% {
        transform: scale(.33);
      }

      80% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }
  }
}
