@import '../../../../resources/colors-and-fonts';

.courier-chart {

  tspan {
    font-size: 14px;
  }

  .bar-chart-tooltip {
    background-color: $tile_background_color;
    border: 1px solid $kashmir_blue;
    padding: 0 10px;
    text-transform: capitalize;
    line-height: 10px;

    .label {
      color: $lighter_text;
    }

    .count {
      color: $selection_color;
    }
  }
}
