@import '../../../../resources/colors-and-fonts';


.delivery-areas-management-component {
  display: flex;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: calc(100vh - 20px);
    align-items: center;
    margin: 10px;
    padding: 10px 10px 0 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    z-index: 1;
    box-shadow: $menu_box_shadow;
    box-sizing: border-box;
    border-radius: 4px;

    .mily-button {
      margin-left: 0;
      width: 100%;
      margin-bottom: 10px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $lighter_text;
      overflow-x: hidden;
      z-index: 10;
      flex-grow: 1;
      background-color: $tile_background_color;

      .da-table-wrapper {
        display: flex;
        background-color: $tile_background_color;
        flex-grow: 1;

        .table-search {
          margin-bottom: 10px;
        }
      }
    }
  }

  .map-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 0;

    .delivery-area-preview {
      height: 100%;
    }

    .empty-page {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 250px;
      flex-grow: 1;
      color: $lighter_text;
    }
  }
}
