@import '../../../../resources/colors-and-fonts';

.form-input-wrapper {
  position: relative;
  margin-bottom: 3px;

  .form-label {
    order: 1;
    width: fit-content;
    margin-bottom: 5px;
    color: $light_text;
    font-weight: $font_wight_form_label;
    font-size: 13px;
    line-height: 21px;
  }

  .input-label {
    margin-bottom: 2px;
  }

  .text-input {
    display: flex;
    flex-direction: column;

    &.label-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .input-label {
        flex-basis: 150px;
        font-weight: 500;
      }
    }

    .default-input {
      order: 2;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 2px;
      border-radius: 4px;
      border: 1px solid $kashmir_blue;
      color: $light_text;
      font-size: 14px;
      font-family: $font_family;
      font-weight: $font_weight_input;
      background-color: $tile_background_color;
      line-height: 22px;

      &.size-m {
        padding: 4px 8px;
      }

      &.size-l {
        padding: 8px;
      }

      &[disabled] + .input-label {
        opacity: .5;
      }

      &:disabled {
        opacity: .5;
      }

      &:hover {
        background-color: $border_color_dark;
      }

      &:valid {
        border-color: $selection_color;
      }

      &:focus {
        outline: none;
        border-color: $selection_color;
        background-color: $tile_background_color;
        box-shadow: 0 0 6px 0 $selection_color;
      }

      &:placeholder-shown {
        box-shadow: none;
      }

      &::placeholder {
        color: $kashmir_blue;
      }

      &:-webkit-autofill {
        &:hover,
        &:focus,
        &:active {
          -webkit-box-shadow: 0 0 0 30px $tile_background_color inset;
          -webkit-text-fill-color: $light_text;
        }
      }
    }
  }

  .checkbox-label {
    font-weight: $font_weight_normal;
    font-size: 16px;
    color: $light_text;

    .highlighted {
      color: $warning_color;
    }
  }

  .toggle-input {
    display: flex;
    align-items: center;

    .form-label {
      order: 0;
      margin-right: auto;
      margin-bottom: 0;
    }

    .react-toggle--checked {
      .react-toggle-track {
        background-color: $selection_color;
      }

      .react-toggle-thumb {
        border: 0;
      }
    }
  }
}
