@import '../../../../../resources/colors-and-fonts';

.bottom-content {
  z-index: 10;
  padding: 10px 5px 5px 5px;
  background: $tile_background_color_with_opacity;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flex-container-totals-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-left: 35px;
    margin-right: 15px;
    margin-bottom: 10px;
    height: fit-content;

    .flex-one-total-info {
      flex-grow: 1;
      flex-basis: 100px;
      display: flex;
    }

    .stat-group {
      display: flex;
      flex-direction: column;
      margin-left: 10%;

      .flex-item-title {
        color: $light_text;
        font-size: clamp(12px, 0.9vw, 14px);
        text-align: left;
        flex-grow: 2;
      }

      .flex-item-value {
        color: $lighter_text;
        font-weight: $font_weight_bold;
        font-size: clamp(16px, 1vw, 20px);
        text-align: left;
      }
    }
  }

  .div-responsive-container {
    flex-grow: 1;
  }

  .custom-bar-chart {
    width: calc(100% - 15px);

    .bar-chart-tooltip {
      background-color: $tile_background_color;
      border: 1px solid $kashmir_blue;
      padding: 0 10px;
      text-transform: capitalize;
      line-height: 10px;

      .label {
        color: $lighter_text;
      }

      .count {
        color: #8884d8;
      }
    }

    .bar-cell {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
