@import '../../../../resources/colors-and-fonts';

.plan-tracking-preview {
  position: relative;
  height: 100vh;

  .plan-tracking-preview-map {
    position: relative;
    height: 100vh;

    &.hidden {
      visibility: hidden;
    }

    .route-toggle-wrapper {
      position: absolute;
      left: 5px;
      top: 0;

      .mode-button {
        color: $lighter_text;
        z-index: 15;

        .icon-live-location {
          font-size: 23px;
        }

        &:hover {
          color: $selection_color;
          cursor: pointer;
        }
      }
    }
  }
}
