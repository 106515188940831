@import '../../../../../resources/colors-and-fonts';

.visible-coverage-point {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 8px;
  }

  .map-points-count {
    font-size: 16px;
    font-weight: $font_weight_normal;
    line-height: 24px;
    margin-right: 4px;
  }

  .background-image {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;

    &:hover {
      cursor: auto;
    }
  }
}
