@import '../../../../../resources/colors-and-fonts';

.button-legend-wrapper {
  margin-left: 10px;
  height: 40px;
  display: flex;

  .button-arrow {
    position: relative;
    height: 0;
    width: 0;
    top: 20px;
    border: 5px solid transparent;
    border-right-color: $tile_background_color;
  }

  .button-legend {
    border-radius: 3px;
    position: relative;
    background-color: $tile_background_color;
    top: 4px;
    padding: 10px 20px 10px 20px;
    color: #fff;
    font-size: 14px;
    font-family: $font_family;
    font-weight: $font_weight_normal;
  }
}
