@import '../../../../resources/colors-and-fonts';

.hub-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 300px;

  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .selections-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .label {
      color: $light_text;
      font-weight: $font_weight_bold;
    }

    .center-selection-wrapper {
      width: 300px;

      .select {
        margin: 5px 0;
      }
    }

    .hub-selection {
      .center-selection-wrapper {
        width: 300px;
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
