@import '../../../../resources/colors-and-fonts';

.table-actions {

  &.card {
    position: absolute;
    right: 5px;
    top: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .icon-save {
    color: $lighter_text;
    font-size: 40px;
    margin-right: 10px;
  }

  .icon-question {
    color: $lighter_text;
    font-size: 40px;
    margin-right: 10px;
  }
}
