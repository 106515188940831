@import '../../../resources/colors-and-fonts';

.logo-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 250px;

  .icon {
    color: $lighter_text;
  }
}
