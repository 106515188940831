@import '../../../../resources/colors-and-fonts';

.table-search {
  background-color: $tile_background_color;
  position: relative;

  .icon {
    position: absolute;
    top: calc(50% - 8px);
    left: 8px;
    color: $kashmir_blue;

    &.active {
      color: $light_text;
    }
  }

  .search {
    width: 100%;
    min-width: 200px;
    border: 1px solid $kashmir_blue;
    background-color: $tile_background_color;
    font-size: 14px;
    color: $light_text;
    font-family: $font_family;
    font-weight: $font_weight_input;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 22px;

    &.size-m {
      padding: 4px 8px 4px 32px;
    }

    &.size-l {
      padding: 8px 8px 8px 32px;
    }

    &:hover {
      background-color: $border_color_dark;
    }

    &:focus {
      outline: none;
      border-color: $selection_color;
      background-color: $tile_background_color;
      box-shadow: 0 0 6px 0 $selection_color;
    }

    &:placeholder-shown {
      box-shadow: none;
    }

    &::placeholder {
      color: $kashmir_blue;
    }

    &:valid {
      border-color: $selection_color;
    }
  }
}
