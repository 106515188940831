@import '../../../resources/colors-and-fonts';

.date-picker-wrapper {
  position: relative;
  width: 100%;
  color: $lighter_text;

  .date-picker-input {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    .dates-wrapper {
      display: flex;
      align-items: center;
    }

    .arrow-icon {
      font-size: 30px;
      cursor: pointer;
    }

    .icon-keyboard-arrow-left {
      margin-right: 20px;
    }

    .icon-keyboard-arrow-right {
      margin-left: 20px;
    }

    .icon-event {
      font-size: 18px;
      margin-right: 10px;
    }

    .dates {
      font-size: 16px;
    }

    .calendar-hover {
      &:hover {
        color: $selection_color;
      }
    }
  }

  .picker-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -156px;
    z-index: 10;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: $tile_background_color;
    border: 1px solid $kashmir_blue;


    .buttons {
      display: flex;

      .confirm-button {
        cursor: pointer;
        margin-right: 30px;
      }
    }
  }

  // sass-lint:disable class-name-format
  .DayPicker {
    &:not(.DayPicker--interactionDisabled) {
      .DayPicker-Day {
        &:not(.DayPicker-Day--disabled) {
          &:not(.DayPicker-Day--selected) {
            &:not(.DayPicker-Day--outside) {
              &:hover {
                background-color: $dim_selection_color;
              }
            }
          }
        }
      }
    }

    .DayPicker-Month {
      border-collapse: separate;
    }

    .DayPicker-WeekNumber {
      outline: none;
    }


    .DayPicker-Day {
      outline: none;
      border: 1px solid transparent;
      border-radius: 0;
    }

    .DayPicker-Day--selected {
      background-color: $selection_color;

      &:not(.DayPicker-Day--disabled) {
        color: #000;
      }
    }
  }
}
