@import '../../../../resources/colors-and-fonts';

.address-card {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;

  .address-info {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: $font_weight_bold;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    padding-top: 2px;
    margin-left: auto;

    .card {
      position: relative;
      right: 0;
      top: 0;
    }

    .icon-question {
      font-size: 25px;
    }

    .icon-save {
      font-size: 25px;
    }
  }
}
