@import '../../../resources/colors-and-fonts';

.real-time-map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .live-location-wrapper {
    position: absolute;
    top: -5px;
    left: 5px;
    z-index: 15;
  }

  .route-toggle-wrapper {
    position: absolute;
    left: 5px;
    top: 35px;
    z-index: 15;
  }

  .pin-legend-wrapper {
    z-index: 15;
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
}
