@import '../../../../resources/colors-and-fonts';

.table-component {
  display: flex;
  flex-direction: column;
  background-color: $tile_background_color;
  color: $lighter_text;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .centered {
    justify-content: center;
    align-items: center;
  }

  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $tile_background_color;
    padding-bottom: 10px;
  }

  .table-cell {
    flex-basis: 100px;
    flex-grow: 1;
  }

  .header {
    display: flex;
    padding: 10px;
    background-color: $tile_background_color;
    font-weight: $font_weight_bold;
    color: $light_text;

    .table-header {
      display: flex;
      align-items: center;

      .sort-icons {
        display: flex;
        font-size: 18px;
        margin-left: 3px;
      }
    }
  }

  .table-body {
    flex-grow: 1;
    overflow: auto;

    .table-row {
      display: flex;
      align-items: center;
      padding: 10px;
      color: $light_text;
      font-size: 14px;

      &:hover {
        background-color: $selection_color;
        color: $lighter_text;
      }

      &:nth-child(odd) {
        background-color: $tile_background_color_with_opacity;

        &:hover {
          background-color: $selection_color;
          color: $lighter_text;
        }
      }

      &:nth-child(even) {
        background-color: $tile_background_color;

        &:hover {
          background-color: $selection_color;
          color: $lighter_text;
        }
      }

      &.warning-row {
        background-color: $warning_color;

        &:hover {
          background-color: $selection_color;
          color: $lighter_text;
        }
      }
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 10px;
    font-size: 14px;
    color: $light_text;

    .page-items-number {
      flex-grow: 1;
      flex-basis: 50px;
    }

    .change-page-wrapper {
      display: flex;
      justify-content: center;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;

        .icon {
          font-size: 20px;
        }

        &:hover {
          cursor: pointer;
        }

        &.disabled {
          opacity: .5;
          cursor: default;
        }
      }

      .pages {
        display: flex;
        align-items: center;

        .page-number {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          margin-right: 5px;
          width: 26px;
          height: 26px;
          box-sizing: border-box;

          &.selected {
            color: $light_text;
            border: 1px solid $selection_color;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .results-count {
      flex-grow: 1;
      flex-basis: 50px;
      text-align: end;
      text-transform: lowercase;
    }
  }
}
