@import '../../../resources/colors-and-fonts';

.plan-tracking-page {
  display: flex;
  height: 100%;
  position: relative;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 20px);
    margin: 10px;
    width: 400px;
    padding: 10px 10px 0 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    z-index: 1;
    box-shadow: $menu_box_shadow;
    box-sizing: border-box;
    border-radius: 4px;

    .label {
      color: $lighter_text;
      font-weight: $font_weight_bold;
      min-width: 70px;
    }

    .selection-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .select {
        width: 100%;
      }
    }

    .date-picker-wrapper {
      margin-bottom: 10px;

      .picker-wrapper {
        z-index: 20;
      }
    }

    .real-time-info-loader {
      margin-bottom: 10px;
    }

    .menu {
      display: flex;
      width: 100%;
      background-color: $tile_background_color;
      flex-grow: 1;
      overflow-x: hidden;
      box-sizing: border-box;

      .table-search {
        margin-bottom: 10px;
      }
    }
  }

  .map-wrapper {
    flex-grow: 1;
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    .plan-preview {
      height: 100%;
    }

    .mapboxgl-ctrl {
      a {
        &:last-child {
          display: none;
        }
      }
    }
  }

  .empty-page {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }

    &.hidden {
      display: none;
    }
  }
}
