@import '../../../../resources/colors-and-fonts';

.heat-map {
  display: flex;
  position: relative;

  .MuiButtonBase-root { // sass-lint:disable-line class-name-format
    position: absolute;
    top: -35px;
    right: 0;
    color: $lighter_text;
    font-family: $font_family;

    &:hover {
      color: $selection_color;
    }
  }

  .axis {
    color: $lighter_text;
    font-family: $font_family;
    font-size: 12px;
  }

  div {
    &.tooltip {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $tile_background_color;
      color: $lighter_text;
      width: 20px;
      height: 20px;
      padding: 5px;
      border: 1px solid $kashmir_blue;
      z-index: 10;
    }
  }

  .legend {
    margin-top: -6px;
  }

  .legend-axis {
    color: $lighter_text;
    font-family: $font_family;
    font-size: 10px;
  }
}
