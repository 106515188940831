@import '../../../resources/colors-and-fonts';

.shipment-timeline-item {
  color: $light_text;
  padding: 10px;
  border: 1px solid $kashmir_blue;
  flex-grow: 1;
  margin-left: 16px;

  &:not(:nth-last-of-type(1)) {
    margin-bottom: 10px;
  }

  .shipment-data-column {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .shipment-data {
      &:first-child {
        margin: 0 10px 0 0;
      }
    }
  }

  .shipment-data {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:not(:nth-last-of-type(1)) {
      margin-bottom: 0;
    }

    .timeline-item-icon {
      margin-right: 5px;
      font-size: 20px;
    }

    .shipment-address-wrong {
      color: $error_color;
    }

    .enter-location {
      text-transform: none;
      padding-left: 5px;
      color: $error_color;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
