@import '../../../../resources/colors-and-fonts';

.table-actions {

  &.card {
    position: absolute;
    right: 5px;
    top: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.table {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }

    &.icon-outgoing-mail {
      font-size: 23px;
      margin-right: 10px;
    }
  }

  .icon-cloud-download {
    margin-right: 10px;
  }

  .icon-edit {
    color: $success_color;
    margin-right: 10px;
  }

  .icon-delete {
    color: $error_color;
  }
}
