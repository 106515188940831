@import '../../../resources/colors-and-fonts';

.home {
  display: flex;
  height: 100vh;

  .label {
    min-width: 110px;
    color: $lighter_text;
    font-weight: $font_weight_bold;
  }

  .home-data-section {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 500px;

    .simplebar-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .filters-section {
        margin-bottom: 10px;
        padding: 10px;
        background-color: $tile_background_color;
        box-shadow: $menu_box_shadow;
        border-radius: 4px;

        .team-selection-wrapper {
          display: flex;
          align-items: center;

          .select {
            width: 100%;
          }
        }

        .courier-selection-wrapper {
          margin-top: 10px;
          display: flex;
          align-items: center;
        }

        .ooh-info-wrapper {
          display: flex;
          align-items: center;

          .label {
            width: 50%;
          }
        }

        .date-filters {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }

        .delivery-filters {
          display: none;
          margin-top: 10px;
          justify-content: space-evenly;
        }
      }

      .data-section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;

        .data-cards {
          display: flex;
          flex-wrap: wrap;
          max-width: 500px;
        }

        .data-cards-loader {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 500px;
          height: 156px;
          margin-bottom: 10px;
          background-color: $tile_background_color;
          box-shadow: $default_box_shadow;
        }

        .chart-loader {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 500px;
          box-shadow: $default_box_shadow;
          background-color: $tile_background_color;
          min-height: 285px;
          box-sizing: border-box;
        }

        .bar-chart-loader {
          margin-top: 10px;
          flex-grow: 1;
        }

        .info-icon-wrapper {
          display: flex;
          align-items: center;
          margin-left: 5px;
          position: relative;

          &:hover {
            cursor: pointer;
            color: $selection_color;

            .icon-background {
              position: absolute;
              background-color: $lighter_text;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              top: 3px;
              left: 3px;
              z-index: 1;
            }
          }

          .icon-info {
            font-size: 24px;
            z-index: 5;
          }
        }
      }
    }
  }

  .map-section {
    flex-grow: 1;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 250px;
      height: 100%;
      color: $lighter_text;
    }
  }

  .no-data {
    margin-bottom: auto;

    .no-data-text {
      color: $lighter_text;
      text-align: center;
    }

    .no-data-call-to-action {
      color: $light_text;
      text-align: center;
    }
  }

  .MuiCircularProgress-root { // sass-lint:disable-line class-name-format
    color: $selection_color;
  }
}
