@import '../../../../resources/colors-and-fonts';

$animation: all 0.2s ease-out;

.language-selection {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 50;

  &:hover {
    cursor: pointer;
    color: $selection_color;
  }

  .language-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      color: $selection_color;
    }

    &.vertical {
      flex-direction: row;

      .icon-language {
        margin-right: 5px;
      }
    }

    .icon-language {
      font-size: 27px;
    }
  }

  .language-label {
    transition: $animation;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    opacity: 0;
  }

  .language-label-extended {
    margin-top: 13px;
    font-size: 12px;
    width: 100%;
    opacity: 1;
  }

  .menu {
    position: absolute;
    background-color: $tile_background_color;
    z-index: 100;
    box-shadow: $default_box_shadow;
    color: $lighter_text;

    li {
      cursor: pointer;

      &:hover {
        color: $selection_color;
      }
    }
  }

  .menu-vertical {
    right: 0;
    top: 40px;
    z-index: 50;
  }

  .menu-horizontal {
    top: 0;
    left: 65px;
    z-index: 50;
  }

  .extended {
    left: 115px;
  }
}
