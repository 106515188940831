@import '../../../../../resources/colors-and-fonts';

.coverage-data-table {
  box-sizing: border-box;
  border-radius: 4px;
  width: 148px;
  height: 122px;
  padding: 8px 10px;
  color: $light_text;

  &.current {
    border: 2px solid $dashboard_color_1;
  }

  &.estimated {
    margin-left: 8px;
    border: 2px solid $selection_color;
  }

  .data-row-percentage {
    display: flex;
    border-bottom: 1px solid $border_color_dark;
    padding-bottom: 8px;

    .data-name {
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .data-value {
      position: relative;
      font-weight: 600;
      margin-left: auto;
    }
  }

  .data-row {
    display: flex;
    align-items: center;

    .icon {
      font-size: 16px;
      margin-right: 4px;
    }

    .data-name {
      font-weight: 400;
      font-size: 13px;
      display: flex;
      align-items: center;
    }

    .data-value {
      font-weight: 600;
      margin-left: auto;
      font-size: 14px;
    }

    .small-font-size {
      font-size: 13px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border_color_dark;
      padding-bottom: 8px;
    }

    &:not(:first-child) {
      padding-top: 8px;
    }
  }

  .no-coverage-data {
    padding: 2px 4px;
    height: 40px;
  }
}
