@import '../../../resources/colors-and-fonts';

.real-time-info-loader {
  width: 100%;

  .refresh {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
    color: $lighter_text;

    .icon-rotate-left {
      margin-right: 10px;
    }

    .refresh-text {
      font-size: 13px;
      text-align: center;
    }

    &:hover {
      cursor: pointer;
      color: $selection_color;
    }
  }

  .loader-wrapper {
    position: relative;
    flex-grow: 1;
    height: 25px;

    .loader {
      position: absolute;
      top: -5px;
      font-size: 30px;
      color: $lighter-text;
      animation: loading 5s infinite;
      animation-timing-function: linear;

      @keyframes loading {
        0% {
          opacity: 0;
          left: 0;
        }

        20% {
          opacity: 1;
        }

        80% {
          opacity: 0.5;
        }

        100% {
          opacity: 0;
          left: 100%;
        }
      }
    }
  }
}
