@import '../../../../resources/colors-and-fonts';

.mily-checkbox-container {
  display: flex;
  align-items: center;

  .label {
    order: 2;
    margin-left: 10px;
    font-size: 14px;
    font-weight: $font_weight_input;
    color: $light_text;
  }

  .checkbox-input {
    position: relative;
    cursor: pointer;
    // hide default checkbox but add margin to keep element height same as new checkbox height
    width: 0;
    height: 0;
    margin: 0 20px 20px 0;

    // Add custom checkbox background
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      box-sizing: border-box;
      border: 2px solid $border_color_light;
      border-radius: 3px;
      padding: 1px;
    }

    &.checked {
      // Checkbox checked icon
      &::after {
        content: '';
        display: block;
        width: 5px;
        height: 10px;
        border: solid #ece7e5;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 7px;
      }
    }

    &.disabled {
      &::before {
        border-color: #989898;
      }
    }

    &:not(.hovered) {
      // Checkbox not checked and not hovered
      &::before {
        background-color: transparent;
      }

      &.checked {
        // Checkbox checked background
        &::before {
          background-color: #00aeef;
          border-color: #00aeef;
        }

        &.disabled {
          &::before {
            background-color: #989898;
            border-color: #989898;
          }
        }
      }
    }

    &.hovered {
      // Checkbox not checked hovered
      &::before {
        background-color: $border_color_dark;
        border-color: $border_color_dark;
      }

      // Checkbox checked hovered
      &.checked {
        &::before {
          background-color: $border_color_dark;
          border-color: $border_color_dark;
        }
      }
    }
  }
}

