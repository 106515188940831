@import '../../../../resources/colors-and-fonts';


.mode-button-wrapper {
  position: relative;
  width: fit-content;
  color: $light_text;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  z-index: 4;

  &.active {
    box-shadow: 0 1px 4px rgba(11, 26, 45, 0.75);
    border-radius: 4px;
  }

  &.with-number {
    .mode-button {
      border-radius: 4px 0 0 4px;
    }
  }

  &.left-tab {
    .mode-button {
      border-radius: 4px 0 0 4px;
      background-color: $tile_background_color_with_opacity;

      &.active {
        background-color: $tile_background_color;
        color: $selection_color;
        border-left: 1px solid $selection_color;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  .mode-button {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 32px;
    background-color: $tile_background_color;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background-color: $border_color_dark;
    }

    &.active {
      color: $selection_color;
      border: 1px solid $selection_color;
    }

    &.with-text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 14px 8px 14px;
      min-width: auto;
      color: $selection_color;
      border: 1px solid $selection_color;
      box-shadow: 0 1px 4px rgba(11, 26, 45, 0.75);

      .icon {
        margin-right: 8px;
      }
    }

    .icon {
      font-weight: $font_weight_bold;
      font-size: 16px;
    }
  }

  .button-number {
    box-sizing: border-box;
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $selection-color;
    font-size: 12px;
    min-height: 32px;
    padding: 6px 7px 6px 7px;
  }

  .menu {
    position: absolute;

    .menu-item {
      display: flex;
      flex-direction: column;
      padding: 8px 16px;
      white-space: nowrap;
      background-color: $background_color;

      &:hover {
        cursor: pointer;
        background-color: $border_color_dark;
      }

      &.active {
        background-color: $selection_color;
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      .selection-label {
        font-size: 14px;
      }
    }
  }

  .menu-vertical {
    left: calc(100% + 10px);
    top: 0;
  }
}
