@import '../../../../resources/colors-and-fonts';

.planning-card {
  .title-wrapper {
    display: flex;
    align-items: center;

    .title-icon {
      font-size: 20px;
      margin-right: 10px;
      color: $kashmir_blue;
    }

    .values {

      .cell-wrapper {
        display: flex;
        align-items: center;

        &:first-child {
          font-weight: $font_weight_bold;
        }

        .cell-header {
          margin-right: 10px;
          font-size: 20px;
          color: $kashmir_blue;
        }

        .empty {
          font-style: italic;
          font-weight: $font_weight_normal;
        }

        .table-actions {
          top: 8px;
        }
      }
    }
  }

  .plan-courier-select {
    margin-top: 10px;
    margin-left: 30px;
  }
}
