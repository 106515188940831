@import '../../../../resources/colors-and-fonts';

.delivery-area-map-view-component {
  position: relative;
  flex-grow: 1;

  .mapboxgl-ctrl {
    a {
      &:last-child {
        display: none;
      }
    }
  }
}
