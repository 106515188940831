@import '../../../../resources/colors-and-fonts';

.bar-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  box-shadow: $default_box_shadow;
  color: $lighter_text;
  font-family: $font_family;
  background-color: $tile_background_color;
  min-height: 250px;
  max-height: unset;

  visibility: visible;
  opacity: 1;
  transition: 300ms ease-in-out;

  &.chart-fade-out {
    opacity: 0;
    height: 0;
    min-height: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: 300ms ease-in-out;
  }

  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .MuiButtonBase-root { // sass-lint:disable-line class-name-format
      color: $lighter_text;
      font-family: $font_family;

      &:hover {
        color: $selection_color;
      }
    }
  }

  .info-dialog-wrapper {
    align-self: flex-start;
  }

  .no-data {
    color: $lighter_text;
    margin-bottom: auto;
    margin-top: auto;

    .no-data-text {
      margin-bottom: 20px;
    }
  }
}
