@import '../../../resources/colors-and-fonts';

.switch-wrapper {
  display: flex;

  .option {
    padding: 4px;
    margin: 5px;
    color: $lighter_text;
    font-family: $font_family;
    font-weight: $font_weight_normal;
    font-size: 14px;
    border-radius: 10px;
    border-color: $kashmir_blue;
    border-width: 1px;
    border-style: solid;

    &:hover {
      cursor: pointer;
      background-color: $dim_selection_color;
    }
  }

  &:first-child {
    margin: 5px 5px 5px 0;
  }

  .active {
    background-color: $selection_color;

    &:hover {
      cursor: pointer;
      background-color: $selection_color;
    }
  }
}
