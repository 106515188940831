@import '../../../../resources/colors-and-fonts';

.pin-legend {
  background-color: $tile_background_color;
  z-index: 10;
  height: auto;
  padding: 5px 10px 10px 10px;
  font-size: 12px;

  .pin {
    &:hover {
      cursor: auto;
    }
  }

  .legend-data {
    width: auto;
    display: grid;
    align-items: center;
    grid-template-columns: 30px 1fr;
    grid-template-rows: 30px 30px 30px 30px;
    grid-template-areas:
    'pin1 delivered'
    'pin3 pickup'
    'pin5 unsuccessful'
    'pin6 number';
  }

  .name {
    padding-top: 2px;
    color: $lighter_text;
  }

  .number {
    padding-top: 5px;
    font-size: 10px;
    display: block;
    white-space: pre-line;
  }

  .additional-number {
    padding-top: 5px;
    display: flex;
    padding-right: 10px;

    .circle {
      position: relative;
      z-index: 15;

      .dot {
        background-color: $kashmir_blue;
        border-radius: 50%;
        color: $lighter_text;
        font-size: 12px;
        font-family: $font_family;
        font-weight: $font-weight_bold;
        padding: 0 3px 0 1px;
      }
    }
  }
}
