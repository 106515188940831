@import '../../../resources/colors-and-fonts';

.shipments-management {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;

  .filter-wrapper {
    background-color: $tile_background_color;
    width: 500px;
    padding: 10px;
    margin-bottom: 10px;

    .label {
      color: $lighter_text;
      font-weight: $font_weight_bold;
    }

    .center-selection-wrapper {
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      .select {
        width: 100%;
      }
    }

    .date-picker-wrapper {
      margin: 10px 0;
    }
  }

  .table-wrapper {
    flex-grow: 1;
  }
}
