@import '../../../../resources/colors-and-fonts';

.map-marker-popup {
  padding: 11px; // TODO rest of the padding is in mapboxgl-popup-content. We should fix this with new design improvements
  font-family: $font_family;
  font-weight: $font-weight_normal;
  min-width: 200px;

  .map-marker-popup-title {
    color: $light_text;
    font-size: 16px;
    font-weight: $font_weight_bold;

    &.strong-border {
      border-bottom: 1px solid #395677;
      padding-bottom: 8px;
    }
  }

  .row-wrapper {
    display: flex;
    align-items: center;
  }

  .sh-codes {
    flex-direction: column;
    align-items: flex-start;
    color: $light_text;

    .delivery-type {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $font_weight_bold;
    }

    .delivery {
      color: $deliveries_color;
    }

    .pickup {
      color: $pickups_color;
    }

    .unsuccessful-attempt {
      color: $warning_color;
    }

    .sh-code {
      display: flex;
      font-size: 17px;
    }
  }

  .description {
    display: block;
    color: $lighter_text;
    font-size: 16px;
    padding-top: 5px;
    padding-left: 0;
    font-style: italic;

    .isochrone-description-row {
      padding-top: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid $border_color_light;
        padding-bottom: 10px;
      }

      .isochrone-description-content {
        display: flex;
        align-items: center;
        font-family: $font_family;
        font-style: normal;
        font-weight: $font_weight_normal;
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:is(:last-child) {
          font-weight: $font_weight_bold;
        }

        .isochrone-description-text {
          margin-left: 5px;
          display: flex;
          align-items: center;
        }

        .entities-count {
          margin-left: auto;
          width: auto;
        }
      }
    }


    .pause {
      color: $warning_color;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .mily-button {
      margin-left: 0;
    }
  }
}
