@import '../../../../resources/colors-and-fonts';


.clustering-icons-component {
  display: flex;
  align-items: center;
  width: 100%;

  .left-menu {
    display: flex;
    margin-left: 20px;
  }

  .right-menu {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    font-size: 25px;
    color: $lighter_text;
    margin-right: 10px;

    .icon {
      &:hover {
        color: $selection_color;
      }
    }
  }

  .input-wrapper {
    margin-left: 10px;
    margin-right: 5px;
    background-color: $tile_background_color;
    border: 1px solid $kashmir_blue;
    box-shadow: $default_box_shadow;

    .input {
      width: 40px;
      font-family: $font_family;
      background-color: $tile_background_color;
      color: $lighter_text;
      border: 0;
      font-size: 16px;
      font-weight: $font_weight_normal;
      outline: none;
    }
  }
}
