@import '../../../../../resources/colors-and-fonts';

.result-message-wrapper {
  position: absolute;
  top: 24px;
  left: 513px;
  display: flex;
  z-index: 10;
  border-radius: 4px;
  padding: 15px;
  color: $tile_background_color;

  &.successful {
    background-color: #a1d0af;
    border: 1px solid $success_color;
  }

  &.warning {
    background-color: #f4d7ac;
    border: 1px solid $warning_color;
  }

  &.error {
    background-color: #f5b9b5;
    border: 1px solid $error_color;
  }


  .icon {
    font-size: 24px;
  }

  .icon-check-circle {
    color: $success_color;
  }

  .icon-warning-triangle {
    color: $warning_color;
  }

  .icon-warning-circle {
    color: $error_color;
  }

  .message {
    margin-left: 10px;
    margin-right: 10px;
  }

  .icon-close {
    font-size: 24px;
  }
}
