@import '../../../../resources/colors-and-fonts';

.bar-chart {
  position: relative;

  .MuiButtonBase-root { // sass-lint:disable-line class-name-format
    position: absolute;
    top: -35px;
    right: 0;
    color: $lighter_text;
    font-family: $font_family;

    &:hover {
      color: $selection_color;
    }
  }

  .axis {
    color: $lighter_text;
    font-family: $font_family;
    font-size: 12px;
  }

  .grid-lines {
    stroke: $lighter_text;
    stroke-dasharray: 10;

    & :first-child {
      stroke-dasharray: none;
    }
  }

  div {
    &.tooltip {
      position: absolute;
      top: 0;
      text-align: center;
      background-color: $tile_background_color;
      padding: 10px;
      border: 1px solid $kashmir_blue;
      font-family: $font_family;
      font-size: 16px;
      z-index: 10;
      width: 120px;

      .deliveries {
        color: $deliveries_color;
      }

      .pickups {
        color: $pickups_color;
      }
    }
  }
}
