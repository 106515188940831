@import '../../../../resources/colors-and-fonts';

.hub-management {
  display: flex;
  height: 100%;
  position: relative;

  .menu-wrapper {
    display: flex;
    height: calc(100% - 20px);
    flex-direction: column;
    padding: 10px 10px 0 10px;
    width: 300px;
    margin: 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 10;
    box-shadow: $menu_box_shadow;
    border-radius: 4px;

    .menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $lighter_text;
      overflow-x: hidden;
      z-index: 10;
      flex-grow: 1;

      .mily-button {
        margin-left: 0;
        width: 100%;
        margin-bottom: 10px;
      }

      .table-wrapper {
        display: flex;
        background-color: $tile_background_color;
        flex-grow: 1;

        .table-search {
          margin-bottom: 10px;
        }
      }
    }
  }

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }
  }

  .hub-map-section {
    flex-grow: 1;
    height: 100%;
    box-sizing: border-box;
  }

}
