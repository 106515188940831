@import '../../../../resources/colors-and-fonts';

.marker-popup-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: $font_weight_bold;
  font-size: 15px;
  color: $lighter_text;


  &.delivery {
    background-color: $deliveries_color;
  }

  &.pickup {
    background-color: $pickups_color;
  }

  &.delivery-dim {
    background-color: $deliveries_dim_color;
  }

  &.pickup-dim {
    background-color: $pickups_dim_color;
  }

  &.unsuccessful-attempt {
    background-color: $warning_color;
  }
}
