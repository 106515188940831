@import '../../../../resources/colors-and-fonts';


.da-table-wrapper {
  display: flex;
  background-color: $tile_background_color;
  flex-grow: 1;

  .table-search {
    margin-bottom: 10px;
  }
}

