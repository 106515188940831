@import '../../../../../resources/colors-and-fonts';

.zone-form {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;

  .form-input-wrapper {
    max-width: 350px;
    box-sizing: border-box;
    margin-bottom: 10px;
    -webkit-column-break-inside: avoid; // Chrome, Safari, Opera
    page-break-inside: avoid; // Firefox
    break-inside: avoid; // IE 10+

    .text-input {
      .input-label {
        color: $lighter_text;
        font-weight: $font_weight_bold;
      }
    }

    .toggle-input {
      padding: 2px;
    }
  }

  .page-title {
    color: $lighter_text;
    font-weight: $font_weight_bold;
    margin-bottom: 10px;
  }

  .page-group {
    background-color: $tile_background_color;
    padding: 10px;
    border-bottom: 2px solid $kashmir_blue;
    text-transform: capitalize;
    column-count: 3;
    column-width: 350px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
