@import '../../../../../resources/colors-and-fonts';

.coverage-input-form {
  padding: 16px 24px 24px 24px;
  font-weight: $font_weight_input;
  color: $light_text;

  .title {
    padding-bottom: 16px;
    font-weight: $font_weight_bold;
  }

  .close-button {
    position: absolute;
    right: 24px;
    top: 12px;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  .divider-line {
    margin: 0 -24px;
  }

  .form-container {
    margin-top: 32px;
    margin-bottom: 24px;

    .input-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.154px;
    }

    &.multi-layer-form {
      margin-bottom: 32px;
    }

    & :nth-last-child(2) {
      .mily-checkbox-container {
        margin-bottom: 0;
      }
    }

    .mily-checkbox-container {
      margin-bottom: 17px;

      .label {
        font-weight: $font_weight_bold;
        font-size: 14px;
      }
    }

    .toggle-input {
      .input-label {
        font-weight: $font_weight_bold;
      }
    }
  }

  .description {
    font-size: 14px;
    font-weight: $font_weight_input;
    font-style: normal;
    margin-bottom: 16px;
    letter-spacing: -0.154px;
    width: fit-content;
  }

  .multi-layer-title {
    margin-bottom: 0;
  }

  .optional {
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 300;
    color: #6c84a7;
  }


  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .step-label {
    flex: auto;
    margin-top: 10px;
    font-size: 14px;
    color: #6c84a7;
  }
}
