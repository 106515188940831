@import '../../../resources/colors-and-fonts';

.couriers-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;

  .step-info-header {
    padding-left: 10px;
  }

  .react-tabs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .tab-list {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      .tab {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        color: $light_text;
        font-family: $font-family;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: $font_weight_bold;
        height: 48px;
        box-sizing: border-box;

        &:hover {
          cursor: pointer;
          color: $selection_color;
        }

        .icon {
          margin-right: 10px;
        }

        .icon-person {
          font-size: 19px;
        }

        .icon-account-group {
          font-size: 24px;
        }
      }

      .active-tab {
        color: $selection_color;
        background-color: $tile-background_color;
      }
    }

    .active-tab-panel {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .courier-management,
      .team-management {
        flex-grow: 1;

        .table-wrapper {
          height: 100%;
          max-height: 100%;
        }
      }

      .team-management {
        .table-component {
          .header {
            .table-cell {
              &:nth-child(2) {
                flex-grow: 3;
              }
            }
          }

          .table-body {
            .table-cell {
              &:nth-child(2) {
                flex-grow: 3;
              }
            }
          }
        }
      }
    }
  }
}
