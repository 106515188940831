@import '../../../resources/colors-and-fonts';

.statistic-page {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
  box-sizing: border-box;

  .selection-section {
    display: flex;
    align-items: center;
    color: $lighter_text;

    .select-wrapper {
      display: flex;
      align-items: center;
      min-width: 300px;
      padding: 0 10px;
      height: 100%;
      background-color: $tile_background_color;
      box-shadow: $menu_box_shadow;
      border-radius: 4px;

      .label {
        margin-right: 10px;
        font-weight: $font_weight_bold;
      }

      .select {
        width: 100%;
      }
    }

    .date-range {
      display: flex;
      flex-direction: column;
      background-color: $tile_background_color;
      padding: 10px;
      margin-left: 10px;
      box-shadow: $menu_box_shadow;
      border-radius: 4px;

      .date-picker-wrapper {
        display: flex;
        margin-bottom: 10px;

        .label {
          min-width: 110px;
          font-weight: $font_weight_bold;
        }
      }

      .quick-links {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        font-size: 14px;
        text-decoration: underline;

        div {
          &:hover {
            color: $selection_color;
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: $selection_color;
          }
        }
      }
    }

    .report-button {
      margin-left: auto;
    }
  }

  .dashboard-grid {
    display: grid;
    margin-top: 10px;
    height: 100%;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 156px 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-areas:
    'table table cards cards'
    'table table insight insight'
    'table table insight insight'
    'table table insight insight'
    'stopsChart stopsChart couriersChart couriersChart'
    'stopsChart stopsChart couriersChart couriersChart'
    'stopsChart stopsChart couriersChart couriersChart';

    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      box-shadow: $default_box_shadow;
      background-color: $tile_background_color;
      color: $lighter_text;
    }

    .cards-wrapper {
      grid-area: cards;

      .data-cards {
        display: flex;
      }
    }

    .insight-wrapper {
      grid-area: insight;

      .insights-section {
        display: flex;
        flex-direction: column;
        box-shadow: $default_box_shadow;
        background-color: $tile_background_color;
        padding: 10px;
        height: 100%;
        box-sizing: border-box;

        .insight {
          display: flex;
          align-items: center;
          color: $lighter_text;
          font-size: 14px;
          padding: 5px;

          & .icon {
            font-size: 20px;
            margin-right: 5px;
            color: $warning_color;
          }
        }


        .MuiAlert-root {    // sass-lint:disable-line class-name-format
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .couriers-chart-wrapper {
      grid-area: couriersChart;
    }

    .stops-chart-wrapper {
      grid-area: stopsChart;
    }

    .table-wrapper {
      grid-area: table;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      box-shadow: $default_box_shadow;

      .table {
        height: 300px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow: auto;

        .flex-3 {
          flex-grow: 3;
        }

        .table-body {
          .table-row {
            .table-cell {
              &:nth-child(2) {
                flex-grow: 3;
              }
            }
          }
        }

        .profile-link {
          display: flex;
          justify-content: center;
          width: fit-content;
          color: $light_text;
          text-decoration: none;
          font-size: 16px;
          margin-left: auto;
          margin-right: auto;

          &:hover {
            color: $selection_color;
          }

          &:visited {
            color: $dim_selection_color;
          }
        }
      }
    }

    .chart-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      box-sizing: border-box;
      background-color: $tile_background_color;
      box-shadow: $default_box_shadow;
    }
  }
}

