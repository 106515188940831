@import '../../../../resources/colors-and-fonts';

.planning-footer {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  bottom: 15px;
  height: 40px;
  left: 0;
  right: 0;

  .data-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    opacity: 0.7;
    text-align: left;
    width: 460px;

    .data-distance {
      margin-right: 20px;
      margin-left: auto;
    }

    .data-duration {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
