@import '../../../../resources/colors-and-fonts';

.mily-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  background-color: $selection_color;
  font-size: 14px;
  font-weight: $font_weight_bold;
  color: $light_text;
  width: fit-content;
  margin-left: 10px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    box-shadow: 0 1px 4px rgba(11, 26, 45, 0.75);
  }

  &.primary {
    background-color: $selection_color;
    border: 1px solid $selection_color;

    &:hover {
      background-color: #31b1ff;
    }

    &.active {
      background-color: #007cc8;
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid $selection_color;

    &:hover {
      background-color: #334860;
    }

    &.active {
      color: $selection_color;
    }
  }

  &.cancel {
    background-color: $kashmir_blue;
  }

  &.disabled {
    background-color: #989898;
    border: 1px solid #989898;
    cursor: not-allowed;

    &:hover {
      background-color: #989898;
    }
  }

  .icon {
    font-size: 16px;
  }

  .left-icon {
    margin-right: 8px;
  }

  .right-icon {
    margin-left: 8px;
  }
}
