@import '../../../../resources/colors-and-fonts';

.input-error {
  display: flex;
  align-items: center;
  min-height: 21px;
  color: $error_color;
  line-height: 21px;

  .align-with-input {
    flex-basis: 156px;
  }

  &.hidden {
    visibility: hidden;
  }

  .error-text {
    padding-left: 4px;
    font-size: 12px;
    font-weight: $font_weight_input;
    width: 100%;
  }
}
