@import '../../../../../resources/colors-and-fonts';

.distance-change-confirmation-wrapper {
  background-color: #0b1a2d;
  font-size: 14px;
  font-weight: $font_weight_normal;

  .confirmation-title {
    display: flex;
    padding: 16px 24px;
    font-size: 16px;
    font-weight: $font_weight_bold;
    border-bottom: 1px solid $border_color_dark;
    line-height: 24px;

    .confirmation-title-exit {
      height: 24px;
      margin-left: auto;
      font-size: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .confirmation-body {
    padding: 32px 24px;
    border-bottom: 1px solid $border_color_dark;

    .confirmation-body-question {
      font-weight: $font_weight_bold;
      font-size: 16px;
      margin-bottom: 16px;
    }

    .confirmation-body-text {
      .confirmation-body-text-paragraph {

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .confirmation-buttons {
    padding: 24px;
    display: flex;
    justify-content: flex-end;

    .mily-button {
      height: 37px;
    }
  }
}
