@import '../../../../resources/colors-and-fonts';
// https://www.w3schools.com/howto/howto_css_switch.asp

.toggle-wrapper {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;

  .toggle {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $selection_color;

      &::before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
      }
    }

    &:not(checked) + .slider {
      background-color: $border_color_light;
    }

    &.hovered + .slider {
      background-color: $border_color_dark;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #f3f9fd;
    -webkit-transition: .4s;
    transition: .4s;

    &::before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 4px;
      top: 3px;
      border-radius: 50%;
      background-color: #f3f9fd;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}
