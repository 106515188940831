@import '../../../../resources/colors-and-fonts';

.heat-map-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  max-width: 500px;
  box-shadow: $default_box_shadow;
  background-color: $tile_background_color;
  min-height: 285px;
  box-sizing: border-box;
  margin-top: 10px;

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    margin-top: auto;

    .no-data-text {
      margin-bottom: 20px;
    }
  }
}
